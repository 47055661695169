import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteCartMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteCartMutation = { __typename?: 'Mutation', deleteCart: { __typename?: 'Cart', id: string } };

export const DeleteCartDocument = gql`
    mutation deleteCart($id: String!) {
  deleteCart(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCartGQL extends Apollo.Mutation<DeleteCartMutation, DeleteCartMutationVariables> {
    document = DeleteCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }