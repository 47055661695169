import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetCartQueryVariables = Types.Exact<{
  cartId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetCartQuery = { __typename?: 'Query', getCart?: { __typename?: 'Cart', createdAt: any, id: string, updatedAt: any, items?: Array<{ __typename?: 'CartItem', quantity: number, product: { __typename?: 'Product', name: string, quantity: number, price: number, discountPercent?: number | null, id: string, slug: string, hidden: boolean, deletedAt?: any | null, pictures: Array<{ __typename?: 'ProductPicture', small: string }> } }> | null } | null };

export const GetCartDocument = gql`
    query getCart($cartId: String) {
  getCart(id: $cartId) {
    createdAt
    id
    items {
      product {
        name
        quantity
        pictures {
          small
        }
        price
        discountPercent
        id
        slug
        hidden
        deletedAt
      }
      quantity
    }
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCartGQL extends Apollo.Query<GetCartQuery, GetCartQueryVariables> {
    document = GetCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }