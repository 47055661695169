import {afterNextRender,computed,effect,inject,Inject,Injectable,linkedSignal,Optional,PLATFORM_ID,signal,WritableSignal} from '@angular/core';
import {catchError,map,Observable,of,tap} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';
import {GetInitialDataQuery,GetInitialDataGQL} from './common/graphql/get-initial-data.generated';
import {PayPalNamespace} from '@paypal/paypal-js';
import {GOOGLE_MAPS,GOOGLE_PLACES,MERCADO_PAGO,PAYPAL} from './shared/constants';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../environments/environment';
import {GetCartGQL,GetCartQuery,GetCartQueryVariables} from './common/graphql/get-cart.generated';
import {CreateCartItemGQL,CreateCartItemMutation,CreateCartItemMutationVariables} from './common/graphql/create-cart-item.generated';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartItemCreatedSnackComponent} from './misc/components/cart-item-created-snack/cart-item-created-snack.component';
import {rxResource} from '@angular/core/rxjs-interop';
import {SnackMessageComponent} from './misc/components/snack-message/snack-message.component';
import {DeleteCartItemGQL,DeleteCartItemMutation,DeleteCartItemMutationVariables} from './common/graphql/delete-cart-item.generated';
import {CartItemDeletedSnackComponent} from './misc/components/cart-item-deleted-snack/cart-item-deleted-snack.component';
import {isPlatformServer} from '@angular/common';
import {GetItemsByIdRequestDto} from './common/dto/get-items-by-id-request.dto';
import {GetProductHistoryCartGQL,GetProductHistoryCartQuery} from './common/graphql/get-product-history-cart.generated';
import {GetProductsRelatedByCartGQL,GetProductsRelatedByCartQuery} from './common/graphql/get-products-related-by-cart.generated';
import {GetItemsRequestDto} from './common/dto/get-items-request.dto';
import {GetProductHistoryGQL,GetProductHistoryQuery} from './common/graphql/get-product-history.generated';
import {DeleteCartGQL,DeleteCartMutation} from './common/graphql/delete-cart.generated';

let hasCookieAccess=false;

async function checkHasCookieAccess(){
// 	// Check if Storage Access API is supported
// 	if (!document.requestStorageAccess) {
// 		// Storage Access API is not supported so best we can do is
// 		// hope it's an older browser that doesn't block 3P cookies.
// 		return true;
// 	}
//
// 	// Check if access has already been granted
// 	if (await document.hasStorageAccess()) {
// 		return true;
// 	}
//
// 	// Check the storage-access permission
// 	// Wrap this in a try/catch for browsers that support the
// 	// Storage Access API but not this permission check
// 	// (e.g. Safari and older versions of Firefox).
// 	let permission;
// 	try {
// 		permission = await navigator.permissions.query(
// 			// @ts-ignore
// 			{name: 'storage-access'}
// 		);
// 	} catch (error) {
// 		// storage-access permission not supported. Assume no cookie access.
// 		return false;
// 	}
//
// 	if (permission) {
// 		if (permission.state === 'granted') {
// 			// Permission has previously been granted so can just call
// 			// requestStorageAccess() without a user interaction and
// 			// it will resolve automatically.
// 			try {
// 				await document.requestStorageAccess();
// 				return true;
// 			} catch (error) {
// 				// This shouldn't really fail if access is granted, but return false
// 				// if it does.
// 				return false;
// 			}
// 		} else if (permission.state === 'prompt') {
// 			// Need to call requestStorageAccess() after a user interaction
// 			// (potentially with a prompt). Can't do anything further here,
// 			// so handle this in the click handler.
// 			return false;
// 		} else if (permission.state === 'denied') {
// 			// Currently not used. See:
// 			// https://github.com/privacycg/storage-access/issues/149
// 			return false;
// 		}
// 	}
//
	// By default return false, though should really be caught by one of above.
	return false;
}

@Injectable({
	providedIn:'root'
})
export class AppService{
	private platformId=inject(PLATFORM_ID);
	private localStorageService=inject(LocalStorageService);
	private readonly getInitialDataGQL=inject(GetInitialDataGQL);
	private readonly getCartGQL=inject(GetCartGQL);
	private readonly createCartItemGQL=inject(CreateCartItemGQL);
	private readonly deleteCartItemGQL=inject(DeleteCartItemGQL);
	private readonly deleteCartGQL=inject(DeleteCartGQL);
	private readonly getProductHistoryCartGQL=inject(GetProductHistoryCartGQL);
	private readonly getProductHistoryGQL=inject(GetProductHistoryGQL);
	private readonly getProductsRelatedByCartGQL=inject(GetProductsRelatedByCartGQL);
	private readonly matSnackBar=inject(MatSnackBar);
	private cartId=signal<string|null>(this.localStorageService.retrieve('cart-id') || null);
	private initialData=rxResource<GetInitialDataQuery,unknown>({
		loader:()=>
			this.getInitialDataGQL.fetch()
			.pipe(map((result)=>result.data))
	});
	private cartResource=rxResource<GetCartQuery,GetCartQueryVariables>({
		request:()=>({cartId:this.cartId()}),
		loader:(params)=>{
			if(isPlatformServer(this.platformId)) return of({getCart:{id:'',items:[],createdAt:'',updatedAt:'',__typename:'Cart'}});
			else return this.getCartGQL.fetch(params.request)
			.pipe(map((result)=>result.data));
		}
	});
	cart=linkedSignal<WritableSignal<GetCartQuery|undefined>,GetCartQuery['getCart']>({
		source:()=>this.cartResource.value,
		computation:(source)=>source()?.getCart
	});
	cartItemsCount=computed<number>(
		()=>this.cart()?.items?.reduce((previousValue:number,currentValue:{quantity:number}):number=>previousValue+currentValue.quantity,0) || 0
	);
	cartSubtotal=computed<number>(
		()=>this.cart()?.items?.reduce(
			(previousValue:number,currentValue:{quantity:number,product:{price:number}}):number=>{
				return previousValue+(currentValue.quantity*currentValue.product.price);
			},
			0
		) || 0
	);
	cartDiscount=computed<number>(
		()=>this.cart()?.items?.reduce(
			(previousValue:number,currentValue:{quantity:number,product:{price:number,discountPercent?:number|null}}):number=>{
				if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price*(currentValue.product.discountPercent/100)));
				else return previousValue;
			},
			0
		) || 0
	);
	cartTotal=computed<number>(
		()=>this.cart()?.items?.reduce(
			(previousValue:number,currentValue:{quantity:number,product:{price:number,discountPercent?:number|null}}):number=>{
				if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price-(currentValue.product.price*(currentValue.product.discountPercent/100))));
				else return previousValue+(currentValue.quantity*currentValue.product.price);
			},
			0
		) || 0
	);
	productCategories=computed<GetInitialDataQuery['getProductCategories']>(
		()=>this.initialData.value()?.getProductCategories ?? []
	);
	stores=computed<GetInitialDataQuery['getStores']>(
		()=>this.initialData.value()?.getStores ?? []
	);
	orderStatuses=computed<GetInitialDataQuery['getOrderStatuses']>(
		()=>this.initialData.value()?.getOrderStatuses ?? []
	);
	paypal?:PayPalNamespace;
	googleMaps?:google.maps.MapsLibrary;
	googlePlaces?:google.maps.PlacesLibrary;
	mercadoPago?:any;
	matchesDarkTheme=signal<boolean|undefined>(undefined);
	theme=signal<string|null>(this.localStorageService.retrieve('theme-id') || null);
	
	constructor(
		@Optional() @Inject(PAYPAL) paypal:Promise<PayPalNamespace>,
		@Optional() @Inject(GOOGLE_MAPS) googleMaps:Promise<Loader>,
		@Optional() @Inject(GOOGLE_PLACES) googlePlaces:Promise<Loader>,
		@Optional() @Inject(MERCADO_PAGO) loadMercadoPago:()=>Promise<unknown>
	){
		effect(()=>{
			const cartId=this.cartId();
			if(!cartId) this.localStorageService.clear('cart-id');
			else this.localStorageService.store('cart-id',cartId);
		});
		
		afterNextRender(async()=>{
			paypal.then((paypal:PayPalNamespace):void=>{
				this.paypal=paypal;
			});
			googleMaps.then(async(googleMaps)=>{
				this.googleMaps= await googleMaps.importLibrary('maps');
			});
			googlePlaces.then(async(googlePlaces)=>{
				this.googlePlaces= await googlePlaces.importLibrary('places');
			});
			await loadMercadoPago();
			// @ts-expect-error window.MercadoPago
			this.mercadoPago=new window.MercadoPago(environment.mercadopago.publicKey);
			console.log('mercadoPago',this.mercadoPago);
			hasCookieAccess= await checkHasCookieAccess();
			console.log('hasCookieAccess',hasCookieAccess);
		});
		
		
	}
	
	toggleDarkTheme():void{
		const theme=this.theme();
		switch(theme){
			case 'app-light-theme':
				this.theme.set('app-dark-theme');
				break;
			case 'app-dark-theme':
				this.theme.set('app-light-theme');
				break;
			case null:
				this.theme.set(this.matchesDarkTheme() ? 'app-light-theme' : 'app-dark-theme');
				break;
		}
	}
	
	getCart(variables:GetCartQueryVariables):Observable<NonNullable<GetCartQuery['getCart']>>{
		const {cartId}=variables;
		return this.getCartGQL
		.fetch({cartId:cartId || this.cartId()},{})
		.pipe(
			map(
				(result)=>{
					if(!result.data?.getCart) throw new Error('data not found');
					return result.data.getCart;
				}
			),
			tap(
				(result)=>{
					this.cartId.set(result.id);
					this.cart.set(result);
				}
			)
		);
	}
	
	createCartItem(variables:CreateCartItemMutationVariables):Observable<CreateCartItemMutation['createCartItem']>{
		return this.createCartItemGQL
		.mutate(variables,{})
		.pipe(
			map((result)=>{
				if(!result.data?.createCartItem) throw new Error('data not found');
				return result.data.createCartItem;
			}),
			tap(()=>{
				this.matSnackBar.openFromComponent(CartItemCreatedSnackComponent,{duration:2000});
			}),
			catchError((error)=>{
				this.matSnackBar.openFromComponent(SnackMessageComponent,{data:{error}});
				throw error;
			})
		);
	}
	
	deleteCartItem(variables:DeleteCartItemMutationVariables):Observable<DeleteCartItemMutation['deleteCartItem']>{
		return this.deleteCartItemGQL
		.mutate(variables,{})
		.pipe(
			map((result)=>{
				if(!result.data?.deleteCartItem) throw new Error('data not found');
				return result.data.deleteCartItem;
			}),
			tap(()=>{
				this.matSnackBar.openFromComponent(CartItemDeletedSnackComponent,{duration:2000});
			}),
			catchError((error)=>{
				this.matSnackBar.openFromComponent(SnackMessageComponent,{data:{error}});
				throw error;
			})
		);
	}
	
	deleteCart(cartId:string):Observable<DeleteCartMutation['deleteCart']>{
		return this.deleteCartGQL
		.mutate({id:cartId},{})
		.pipe(
			map((result)=>{
				if(!result.data?.deleteCart) throw new Error('data not found');
				return result.data.deleteCart;
			}),
			tap(()=>{
				this.cartId.set(null);
			}),
			catchError((error)=>{
				this.matSnackBar.openFromComponent(SnackMessageComponent,{data:{error}});
				throw error;
			})
		);
	}
	
	getProductsRelatedByCart(variables:GetItemsByIdRequestDto):Observable<GetProductsRelatedByCartQuery['getProductsRelatedByCart']>{
		return this.getProductsRelatedByCartGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result)=>{
					if(!result.data?.getProductsRelatedByCart) throw new Error('data not found');
					return result.data.getProductsRelatedByCart;
				}
			)
		);
	}
	
	getProductHistoryCart(variables:GetItemsByIdRequestDto):Observable<GetProductHistoryCartQuery['getProductHistoryCart']>{
		return this.getProductHistoryCartGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result)=>{
					if(!result.data?.getProductHistoryCart) throw new Error('data not found');
					return result.data.getProductHistoryCart;
				}
			)
		);
	}
	
	getProductHistory(variables:GetItemsRequestDto):Observable<GetProductHistoryQuery['getProductHistory']>{
		return this.getProductHistoryGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result)=>{
					if(!result.data?.getProductHistory) throw new Error('data not found');
					return result.data.getProductHistory;
				}
			)
		);
	}
	
}
