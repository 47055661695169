import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductHistoryCartQueryVariables = Types.Exact<{
  limit: Types.Scalars['Float']['input'];
  skip: Types.Scalars['Float']['input'];
  id: Types.Scalars['String']['input'];
}>;


export type GetProductHistoryCartQuery = { __typename?: 'Query', getProductHistoryCart: Array<{ __typename?: 'Product', createdAt: any, id: string, name: string, price: number, quantity: number, slug: string, updatedAt: any, discountPercent?: number | null, hidden: boolean, deletedAt?: any | null, pictures: Array<{ __typename?: 'ProductPicture', large: string, small: string }> }> };

export const GetProductHistoryCartDocument = gql`
    query getProductHistoryCart($limit: Float!, $skip: Float!, $id: String!) {
  getProductHistoryCart(id: $id, limit: $limit, skip: $skip) {
    createdAt
    id
    name
    pictures {
      large
      small
    }
    price
    quantity
    slug
    updatedAt
    discountPercent
    hidden
    deletedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductHistoryCartGQL extends Apollo.Query<GetProductHistoryCartQuery, GetProductHistoryCartQueryVariables> {
    document = GetProductHistoryCartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }