import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetInitialDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInitialDataQuery = { __typename?: 'Query', getProductCategories: Array<{ __typename?: 'ProductCategory', createdAt: any, label: string, id: string, updatedAt: any, slug: string }>, getStores: Array<{ __typename?: 'Store', city: string, country: string, createdAt: any, id: string, line1: string, line2?: string | null, name: string, phone: string, placeId?: string | null, state: string, updatedAt: any, zip: string, location: { __typename?: 'StoreLocation', coordinates: Array<number>, type: string } }>, getOrderStatuses: Array<{ __typename?: 'OrderStatus', updatedAt: any, id: string, createdAt: any, label: string, icon: string, order?: number | null, description: string }> };

export const GetInitialDataDocument = gql`
    query getInitialData {
  getProductCategories {
    createdAt
    label
    id
    updatedAt
    slug
  }
  getStores {
    city
    country
    createdAt
    id
    line1
    line2
    location {
      coordinates
      type
    }
    name
    phone
    placeId
    state
    updatedAt
    zip
  }
  getOrderStatuses {
    updatedAt
    id
    createdAt
    label
    icon
    order
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInitialDataGQL extends Apollo.Query<GetInitialDataQuery, GetInitialDataQueryVariables> {
    document = GetInitialDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }